import { Sheet } from "react-modal-sheet";
import icon12 from "./../assets/icon12.png";
import closeIcon from "./../assets/closeicon.png";
import Button from "./Button/Button";
import useUserInfo from "../hooks/userInfo";
import { updateUserInfo, tg_id } from "../api/apiFunctions";
import { useState } from "react";
import { CARD_IMAGES } from "../constant";

const MineModal = ({ categoryData, setShowMineModal, handleMineClaim }) => {
  const { userData, isLoading } = useUserInfo();

  return (
    <Sheet
      isOpen={true}
      onClose={() => setShowMineModal(false)}
      detent="content-height"
    >
      <Sheet.Container
        style={{ backgroundColor: "#011662" }}
        className="border-t-2 border-t-[#BCE7FF] rounded-t-3xl max-h-[75%] pb-10"
      >
        <Sheet.Header className="relative mt-4">
          <div
            className="absolute right-3 cursor-pointer z-10"
            onClick={() => setShowMineModal(false)}
          >
            <img
              src={closeIcon}
              alt="close"
              width={32}
              height={32}
              className="aspect-square"
            />
          </div>
        </Sheet.Header>
        <Sheet.Content>
          <div className="mx-auto mt-10">
            <img
              src={
                CARD_IMAGES.find((image) => image.alt === categoryData.iconName)
                  ?.icon
              }
              alt={categoryData.name}
              className="aspect-auto"
            />
          </div>
          <div className="text-center text-white text-3xl mt-7">
            {categoryData.name}
          </div>
          <div className="text-center text-white text-md">Profit per hour</div>

          <div className="flex gap-4 justify-center items-center mt-7">
            <div>
              <img src={icon12} alt="icon-12" height={35} width={35} />
            </div>
            <div className="text-center text-white text-lg">
              +{categoryData.perHourAddOn}
            </div>
          </div>
          <div className="flex gap-4 justify-center items-center mt-7">
            <div>
              <img src={icon12} alt="icon-12" height={35} width={35} />
            </div>
            <div className="text-center text-white text-3xl">
              +{categoryData.price}
            </div>
          </div>

          {userData.coinBalance >= categoryData.price || isLoading ? (
            isLoading ? (
              <Button
                title="Processing..."
                className="mx-auto min-w-[249px] text-[#BCE7FF] font-medium text-xl h-[74px] rounded-md mt-10"
              />
            ) : (
              <Button
                title="Go ahead"
                onClick={handleMineClaim}
                className="mx-auto min-w-[249px] text-[#BCE7FF] font-medium text-xl h-[74px] rounded-md mt-10"
              />
            )
          ) : (
            <Button
              title="Insufficient funds"
              className="mx-auto min-w-[249px] text-[#BCE7FF] font-medium text-xl h-[74px] rounded-md mt-10"
              bgColor="bg-[#06357d]"
            />
          )}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default MineModal;
