import React from 'react'

const DailyComboCard = ({ dailyComboArr = [], cardImages }) => {
    const cards = [...dailyComboArr];
    while (cards.length < 3) {
        cards.push(null);
    }

    return (
        <>
            {cards.map((card, index) => {
                const image = cardImages.find(image => image.alt === card);
                return (
                    <div key={index} className="border border-[#FFBA07] rounded-[20px] aspect-square w-[110px] flex items-center justify-center">
                        {image ? <img src={image.icon} alt={card} /> : null}
                    </div>
                );
            })}
        </>
    )
}

export default DailyComboCard