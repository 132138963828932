import { useState, useEffect, useMemo, useRef } from "react";
import { toast } from "react-hot-toast";
import {
  getUserInfo,
  getSettingsDetails,
  updateUserInfo,
  tg_id,
} from "../api/apiFunctions";
import { BOTTOM_TAB } from "../constant";

const useUserInfo = () => {
  const [userData, setUserData] = useState({});
  const [settingData, setSettingData] = useState({});
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [turboStatus, setTurboStatus] = useState(false);
  const [selectedTab, setSelectedTab] = useState(BOTTOM_TAB[0]);
  const [selectedDailyReward, setSelectedDailyReward] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMineModal, setShowMineModal] = useState(false);
  const [categoryData, setCategoryData] = useState({});
  const [ponchBotPopup, setPonchBotPopup] = useState({ show: false });
  const [dailyComboArr, setDailyComboArr] = useState([]);

  const intervalRef3 = useRef(null);
  const pendingUpdate = useRef({});
  const counter = useRef(0);
  const lastUpdateTime = useRef(Date.now());

  const handleFullEnergy = () => {
    if (userData?.fullTankUses < 3) {
      const tempFullEnergy = {
        energy: userData.maxEnergy,
        fullTankUses: userData.fullTankUses + 1,
      };
      setUserData({
        ...userData,
        ...tempFullEnergy,
      });
      updateUserInfo(tempFullEnergy, tg_id);
    }
    setSelectedTab(BOTTOM_TAB[0]);
  };

  const handleTurbo = () => {
    if (userData?.tapingGuruUses < 3) {
      setTurboStatus(true);
      setUserData((prev) => ({
        ...prev,
        tapingGuruUses: prev.tapingGuruUses + 1,
      }));
      updateUserInfo({ tapingGuruUses: userData.tapingGuruUses + 1 }, tg_id);
      setTimeout(() => {
        setTurboStatus(false);
      }, 20000);
    }

    setSelectedTab(BOTTOM_TAB[0]);
  };

  const handelMultiTap = (price) => {
    if (userData.coinBalance >= price) {
      setUserData((prev) => ({
        ...prev,
        coinBalance: prev.coinBalance - price,
        multitap: prev.multitap + 1,
      }));
      updateUserInfo(
        {
          coinBalance: userData.coinBalance - price,
          energy: userData.energy,
          multitap: userData.multitap + 1,
        },
        tg_id
      );
    } else {
      toast.error("Not enough coins to buy Multitap.");
    }
    setSelectedTab(BOTTOM_TAB[0]);
  };

  const handleEnergyLimit = (price) => {
    if (userData.coinBalance >= price) {
      setUserData((prev) => ({
        ...prev,
        coinBalance: prev.coinBalance - price,
        maxEnergy: prev.maxEnergy + 500,
      }));
      updateUserInfo(
        {
          coinBalance: userData.coinBalance - price,
          energy: userData.energy,
          maxEnergy: userData.maxEnergy + 500,
        },
        tg_id
      );
    } else {
      toast.error("Not enough coins to buy Energy Limit.");
    }
    setSelectedTab(BOTTOM_TAB[0]);
  };

  const handleTap = () => {
    // let tempEnergy = userData?.energy;
    // let tempCoinBalance = userData?.coinBalance;
    if (userData?.multitap) {
      if (userData.energy >= userData.multitap || turboStatus) {
        let energy, coinBalance, totalTaps;
        if (!turboStatus) {
          energy =
            userData.energy -
            userData.multitap * (userData.boostX2Active ? 2 : 1);
          coinBalance =
            userData.coinBalance +
            userData.multitap * (userData.boostX2Active ? 2 : 1);
          totalTaps =
            userData.totalTaps +
            userData.multitap * (userData.boostX2Active ? 2 : 1);
        } else {
          energy = userData.energy;
          coinBalance =
            userData.coinBalance +
            userData.multitap * (userData.boostX2Active ? 2 : 1) * 5;
          totalTaps =
            userData.totalTaps +
            userData.multitap * (userData.boostX2Active ? 2 : 1) * 5;
        }

        setUserData((prev) => ({
          ...prev,
          energy,
          coinBalance,
          totalTaps,
        }));
      }

      // if (userData?.energy >= userData?.multitap) {
      //   tempCoinBalance += userData?.multitap;
      //   tempEnergy -= userData?.multitap;
      //   setUserData({
      //     ...userData,
      //     coinBalance: tempCoinBalance,
      //     energy: tempEnergy,
      //   });
      // }
    }
  };

  const handleInfoCTA = () => {
    setIsBottomSheetOpen(true);
  };

  const prices = useMemo(() => {
    return {
      multiTapPrice:
        userData?.multitap <= settingData?.multiTapPrice?.length
          ? settingData?.multiTapPrice[userData?.multitap - 1]
          : null,
      energyLimitPrice:
        userData?.maxEnergy / 500 <= settingData?.energyLimitPrice?.length
          ? settingData.energyLimitPrice[userData?.maxEnergy / 500 - 1]
          : null,
      rechargingSpeedPrice:
        userData?.rechargeSpeed <= settingData?.rechargingSpeedPrice?.length
          ? settingData?.rechargingSpeedPrice[userData?.rechargeSpeed - 1]
          : null,
    };
  }, [settingData, userData]);

  const isWelcomeRewardClaimed = useMemo(() => {
    const isClaimed = userData?.socialsJoined?.[0]?.tasks?.findIndex(
      (item) => item?.taskId === "665f09b688b1e21381067575"
    );
    return isClaimed;
  }, [settingData, userData]);

  const handleWelcomePoints = () => {
    if (userData?.socialsJoined) {
      const isClaimed = userData?.socialsJoined?.[0]?.tasks?.findIndex(
        (item) => item?.taskId === "665f09b688b1e21381067575"
      );
      if (isClaimed === undefined || isClaimed === -1) {
        let tempTask = [];
        if (userData?.socialsJoined?.length) {
          if (userData?.socialsJoined[0].tasks?.length) {
            tempTask = [...userData?.socialsJoined[0].tasks];
          } else {
            tempTask = [];
          }
        }

        const tempSocialsJoinedTask = [
          {
            tasks: [
              ...tempTask,
              {
                taskId: "665f09b688b1e21381067575",
                claimed: true,
                done: true,
              },
            ],
            socialsId: settingData?.socials[0]._id,
          },
        ];
        window?.Telegram?.WebApp?.HapticFeedback?.notificationOccurred(
          "success"
        );
        let updatedBlance = userData?.coinBalance + settingData?.welcomeReward;
        setUserData((prev) => ({
          ...prev,
          coinBalance: updatedBlance,
          socialsJoined: tempSocialsJoinedTask,
        }));
        updateUserInfo(
          {
            coinBalance: updatedBlance,
            socialsJoined: tempSocialsJoinedTask,
          },
          tg_id
        );
        setSelectedTab(BOTTOM_TAB[0]);
      } else {
        toast.error("Welcome Reward Already Claimed!");
        setSelectedTab(BOTTOM_TAB[0]);
      }
    }
  };

  const handleTaskSocial = (socialObj) => {
    if (userData?.socialsJoined) {
      const isExistInJoined = userData?.socialsJoined[0]?.tasks?.find(
        (item) => item?.taskId === socialObj?._id
      );

      if (isExistInJoined) {
        const isExistInJoinedIndex =
          userData?.socialsJoined[0]?.tasks?.findIndex(
            (item) => item?.taskId === socialObj?._id
          );
        let tempUpdatedTask = [];
        if (userData?.socialsJoined?.length) {
          if (userData?.socialsJoined[0].tasks?.length) {
            tempUpdatedTask = [...userData?.socialsJoined[0]?.tasks];
          } else {
            tempUpdatedTask = [];
          }
        }
        tempUpdatedTask[isExistInJoinedIndex] = {
          taskId: socialObj?._id,
          claimed: true,
          done: true,
        };

        if (isExistInJoined?.claimed) {
          if (isExistInJoined?.done) {
            // Done
            toast.error("Reward Already Claimed!");
            setSelectedTab(BOTTOM_TAB[0]);
            return;
          }
          // Claimed
          const tempSocialsJoinedTask = [
            {
              tasks: tempUpdatedTask,
              socialsId: settingData?.socials[0]._id,
            },
          ];

          let updatedBlance = userData?.coinBalance + socialObj?.reward;
          setUserData((prev) => ({
            ...prev,
            coinBalance: updatedBlance,
            socialsJoined: tempSocialsJoinedTask,
          }));
          updateUserInfo(
            {
              coinBalance: updatedBlance,
              socialsJoined: tempSocialsJoinedTask,
            },
            tg_id
          );
          setSelectedTab(BOTTOM_TAB[0]);
        }
      } else {
        let tempTask = [];
        if (userData?.socialsJoined?.length) {
          if (userData?.socialsJoined[0].tasks?.length) {
            tempTask = [...userData?.socialsJoined[0].tasks];
          } else {
            tempTask = [];
          }
        }
        const tempSocialsJoinedTask = [
          {
            tasks: [
              ...tempTask,
              {
                taskId: socialObj?._id,
                claimed: true,
                done: false,
              },
            ],
            socialsId: "665f099288b1e318310600f6",
          },
        ];
        setUserData((prev) => ({
          ...prev,
          socialsJoined: tempSocialsJoinedTask,
        }));
        updateUserInfo(
          {
            socialsJoined: tempSocialsJoinedTask,
          },
          tg_id
        );
        window.open(socialObj?.link, "_blank");
      }
    }
  };

  const handleClaimBonus = () => {
    if (userData?.dailyReward[selectedDailyReward] > 0) {
      let updatedBlance =
        userData?.coinBalance + userData?.dailyReward[selectedDailyReward];
      let updatedDailyReward = [...userData?.dailyReward];
      updatedDailyReward[selectedDailyReward] = 0;

      setUserData((prev) => ({
        ...prev,
        coinBalance: updatedBlance,
        dailyReward: updatedDailyReward,
      }));
      updateUserInfo(
        {
          coinBalance: updatedBlance,
          dailyReward: updatedDailyReward,
        },
        tg_id
      );
    } else if (userData?.dailyReward[selectedDailyReward] === 0) {
      toast.error("Already Claimed!");
    } else {
      toast.error("Selected Daily Reward To Claim!");
    }
  };

  const copyReferralLink = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const addUniqueId = (input, id) => {
    const arr = JSON.parse(JSON.stringify(input))
    if (!arr.includes(id)) {
      arr.push(id);
    }
    return arr
  }

  const handleMineClaim = () => {
    setIsLoading(true);
    const categoryToUpdate = userData?.toyFactory?.find(
      (category) => category?.categoryId === categoryData?.categoryId
    );
    if (categoryToUpdate) {
      categoryToUpdate.level = categoryData.level + 1;
    }
    const dailyComboUser = addUniqueId(userData.dailyCombo, categoryData.categoryId)

    const coinBalToUpdate = userData?.coinBalance - categoryData?.price;
    const profitPerHourUpdated =
      userData?.profitPerHour + categoryData?.perHourAddOn;

    setUserData((prev) => ({
      ...prev,
      coinBalance: coinBalToUpdate,
      profitPerHour: profitPerHourUpdated,
      toyFactory: userData.toyFactory,
      dailyCombo: dailyComboUser
    }));

    updateUserInfo(
      {
        coinBalance: coinBalToUpdate,
        profitPerHour: profitPerHourUpdated,
        toyFactory: userData.toyFactory,
        dailyCombo: dailyComboUser
      },
      tg_id
    );
    setShowMineModal(false);
    setIsLoading(false);
  };

  const updateInitialData = (data) => {
    const elapsedHours = Math.floor(
      (Date.now() - new Date(data.lastEnergyUpdate).getTime()) /
      (1000 * 60 * 60)
    );
    let elapsedSeconds;
    if (elapsedHours > 12) {
      elapsedSeconds = 12 * 60 * 60;
    } else {
      elapsedSeconds =
        (Date.now() - new Date(data.lastEnergyUpdate).getTime()) / 1000;
    }

    if (data.profitPerHour) {
      const maxRewardElapsedSeconds = 3600 * 3;
      if (elapsedSeconds > maxRewardElapsedSeconds) {
        elapsedSeconds = maxRewardElapsedSeconds;
      }
      const profit = (data.profitPerHour / (1 * 60 * 60)) * elapsedSeconds;

      setPonchBotPopup((prev) => {
        return {
          ...prev,
          show: true,
          profit
        }
      })
    }
  };

  const handlePonchBotPopup = ({ botTaps, energy, profit }) => {
    if (ponchBotPopup.show) {
      window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred("success");
      if (botTaps && energy) {
        setUserData((prev) => ({
          ...prev,
          coinBalance: prev.coinBalance + botTaps + profit,
          totalTaps: prev.totalTaps + botTaps,
          energy,
        }));

        updateUserInfo({
          coinBalance: userData.coinBalance + botTaps + profit,
          totalTaps: userData.totalTaps + botTaps,
          energy,
        }, tg_id);
      } else {
        setUserData((prev) => ({
          ...prev,
          coinBalance: prev.coinBalance + profit
        }));

        updateUserInfo({
          coinBalance: userData.coinBalance + profit
        }, tg_id);
      }

      setPonchBotPopup({ show: false });
    }
  };


  useEffect(() => {
    if (settingData.dailyCombo && userData?.dailyCombo?.length) {
      const commonElements = new Set(settingData.dailyCombo.filter(element => userData.dailyCombo.includes(element)));
      if (commonElements) {
        const iconNames = settingData.toyFactory.reduce((result, toy) => {
          if (commonElements.has(toy._id)) {
            result.push(toy.iconName);
          }
          return result;
        }, []);
        setDailyComboArr(iconNames);
        if (commonElements.size === settingData.dailyCombo.length && !userData.dailyComboRewardClaimed) {
          const updatedCoinBalance = userData.coinBalance + settingData.dailyComboReward;
          setUserData((prev) => ({
            ...prev,
            coinBalance: updatedCoinBalance,
            dailyComboRewardClaimed: true
          }));
          updateUserInfo({ coinBalance: updatedCoinBalance, dailyComboRewardClaimed: true }, tg_id);
        }
      }
    }
  }, [userData, userData.dailyCombo])

  useEffect(() => {
    getUserInfo(tg_id)
      .then((res) => {
        setUserData(res);
        updateInitialData(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getSettingsDetails()
      .then((res) => {
        setSettingData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const setUserMineData = async () => {
    if (settingData && userData) {
      if (!userData?.toyFactory?.length) {
        const toyFactoryData = [];
        settingData?.toyFactory?.map((category) => {
          toyFactoryData.push({
            categoryId: category._id,
            level: 0,
          });
        });
        const response = await updateUserInfo(
          { toyFactory: toyFactoryData },
          tg_id
        );
        if (response) {
          setUserData((prev) => {
            return {
              ...prev,
              toyFactory: toyFactoryData,
            };
          });
        }
      }
    }
  };

  useEffect(() => {
    if (userData && settingData) {
      setUserMineData();
    }
  }, [settingData, userData]);

  useEffect(() => {
    const updateCount = () => {
      let tempEnergy = userData?.energy;
      if (userData?.energy < userData?.maxEnergy) {
        let newEnergy = tempEnergy + userData?.rechargeSpeed;
        if (newEnergy > userData?.maxEnergy) {
          tempEnergy = userData?.maxEnergy;
        } else {
          tempEnergy = newEnergy;
        }
      }
      setUserData({ ...userData, energy: tempEnergy });
    };
    let intervalId;
    if (userData?._id) {
      intervalId = setInterval(updateCount, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [userData]);

  useEffect(() => {
    let intervalId;
    if (userData?._id) {
      let tempUpdatedUserData = {
        coinBalance: userData?.coinBalance,
        energy: userData?.energy,
      };

      intervalId = setInterval(() => {
        updateUserInfo(tempUpdatedUserData, tg_id)
          .then((res) => { })
          .catch((err) => console.log(err));
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [userData]);

  // mine
  useEffect(() => {
    if (intervalRef3.current) {
      clearInterval(intervalRef3.current);
    }

    if (userData.profitPerHour) {
      intervalRef3.current = setInterval(() => {
        const profit = userData.profitPerHour / (1 * 60 * 60);
        const updatedCoinBalance = userData.coinBalance + profit;
        setUserData((prev) => ({
          ...prev,
          coinBalance: updatedCoinBalance,
        }));
        pendingUpdate.current = {
          coinBalance: updatedCoinBalance,
        };
        counter.current += 1;
      }, 1000);
    } else {
      clearInterval(intervalRef3.current);
    }
  }, [userData.coinBalance, userData.profitPerHour]);

  useEffect(() => {
    if (userData.profitPerHour) {
      const checkUpdateInterval = setInterval(() => {
        if (
          Date.now() - lastUpdateTime.current >= 1000 &&
          counter.current > 0
        ) {
          counter.current = 0;
          lastUpdateTime.current = Date.now();
          updateUserInfo(pendingUpdate.current, tg_id);
        }
      }, 2000);
      return () => clearInterval(checkUpdateInterval);
    }
  }, [userData.profitPerHour]);

  return {
    userData,
    settingData,
    isBottomSheetOpen,
    prices,
    handleTap,
    handleFullEnergy,
    setIsBottomSheetOpen,
    handleInfoCTA,
    selectedTab,
    setSelectedTab,
    handleTurbo,
    handelMultiTap,
    handleEnergyLimit,
    isWelcomeRewardClaimed,
    handleWelcomePoints,
    handleTaskSocial,
    handleClaimBonus,
    selectedDailyReward,
    setSelectedDailyReward,
    copyReferralLink,
    isCopied,
    tg_id,
    turboStatus,
    setUserData,
    showMineModal,
    setShowMineModal,
    categoryData,
    setCategoryData,
    handleMineClaim,
    ponchBotPopup,
    handlePonchBotPopup,
    dailyComboArr
  };
};

export default useUserInfo;
