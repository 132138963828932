import React from "react";
import LockedIcon from "../../assets/locked.png";

const Button = ({
  title,
  className,
  onClick = () => { },
  isShowIcon = false,
  icon,
  bgColor = "bg-[#0053D0]",
  overlay = false
}) => {
  return (
    <div
      className={`cursor-pointer ${bgColor} flex items-center justify-center gap-1 ${className} ${overlay ? 'relative' : ''}`}
      onClick={onClick}
    >
      {overlay && <div className="absolute bg-[#27272aad] w-full h-full flex justify-center items-center rounded-md">
        <img src={LockedIcon} alt="" className="w-6" />
      </div>}
      <p>{title}</p>
      {isShowIcon ? (
        <div>
          <img src={icon} alt="button-ion" width={28} height={28} />
        </div>
      ) : null}
    </div>
  );
};

export default Button;
