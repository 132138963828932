import { Sheet } from "react-modal-sheet";
import icon12 from "./../assets/icon12.png";
import closeIcon from "./../assets/closeicon.png";
import Button from "./Button/Button";
import useUserInfo from "../hooks/userInfo";
import { updateUserInfo, tg_id } from "../api/apiFunctions";
import { useState } from "react";
import { CARD_IMAGES } from "../constant";
import { formatNumber } from "../constant/helper"


const MineClaimModal = ({ ponchBotPopup, handlePonchBotPopup }) => {

  return (
    <Sheet
      isOpen={true}
      onClose={() => handlePonchBotPopup(ponchBotPopup)}
      detent="content-height"
    >
      <Sheet.Container
        style={{ backgroundColor: "#011662" }}
        className="border-t-2 border-t-[#BCE7FF] rounded-t-3xl max-h-[75%] pb-10"
      >
        <Sheet.Header className="relative mt-4">
          <div
            className="absolute right-3 cursor-pointer z-10"
            onClick={() => handlePonchBotPopup(ponchBotPopup)}
          >
            <img
              src={closeIcon}
              alt="close"
              width={32}
              height={32}
              className="aspect-square"
            />
          </div>
        </Sheet.Header>
        <Sheet.Content>
          <div className="flex gap-4 justify-center items-center mt-7">
            <div>
              <img src={icon12} alt="icon-12" />
            </div>
          </div>
          <div className="flex gap-4 justify-center items-center mt-7">
            <div className="text-center text-white text-3xl">
              {formatNumber(ponchBotPopup.profit)}
            </div>
          </div>

          <Button
            title="Thank you Twelvetele"
            onClick={() => handlePonchBotPopup(ponchBotPopup)}
            className="mx-auto min-w-[249px] text-[#BCE7FF] font-medium text-xl h-[74px] rounded-md mt-10"
          />
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default MineClaimModal;
