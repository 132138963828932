import React from "react";
import icon12 from "../../assets/icon12.png";
import infoIcon from "../../assets/Info.png";

const Stats = ({
  title,
  titleStyle,
  isShowIcon = false,
  statsText,
  isShowCTA = false,
  handleCTA = () => {},
}) => {
  return (
    <div className="py-1 bg-[#002B6A] rounded min-w-[100px] w-auto">
      <div className={`font-medium  text-xs text-center ${titleStyle}`}>
        {title}
      </div>
      <div className="w-full flex items-center justify-center gap-3 py-1">
        {isShowIcon ? (
          <div className="aspect-square">
            <img src={icon12} alt="tweleve-icon" height={20} width={20} />
          </div>
        ) : null}
        <p className="text-white text-xs">{statsText}</p>
        {isShowCTA ? (
          <div className="aspect-square cursor-pointer" onClick={handleCTA}>
            <img src={infoIcon} alt="tweleve-icon" height={20} width={20} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Stats;
