import React from "react";
import { BOTTOM_TAB } from "../../constant";

const Layout = ({
  children,
  selectedTab,
  handleSelectedTab,
  isBottomSheetOpen,
}) => {
  return (
    <div
      className={`mx-auto w-full min-h-[100vh] max-w-[720px] lg:hidden ${
        isBottomSheetOpen ? "bg-[#8BA4FE]/30" : "bg-[#011662]"
      }  relative  overflow-y-scroll pb-32`}
    >
      {children}

      <div className="fixed bottom-5 left-2 bg-gradient-to-b from-[#002B6A] to-[#0053D0] rounded-[10px] w-[95%] p-[10px]  flex justify-between mx-auto mt-5 border-t border-t-[#04A5FF] border-r border-r-[#04A5FF]/50 border-b border-b-[#106596]/50 border-l border-l-[#106596] z-10">
        {BOTTOM_TAB.map((item, idx) => (
          <div
            className={`aspect-square min-w-[50px] flex items-center justify-center cursor-pointer rounded-md ${
              item.id === selectedTab.id ? "bg-[#00A4FFF0]" : "bg-[#233C7B]"
            }`}
            key={item.id}
            onClick={() => handleSelectedTab(idx)}
          >
            <img
              src={item.id === selectedTab.id ? item.selectedIcon : item.icon}
              alt={item.alt}
              height={32}
              width={32}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Layout;
