import React, { useState, useEffect } from "react";
import { Sheet } from "react-modal-sheet";
import { Line } from "rc-progress";
import Layout from "./Components/Layout/Layout";
import Button from "./Components/Button/Button";
import Stats from "./Components/Stats/Stats";
import icon12 from "./assets/icon12.png";
import tapIcon from "./assets/taparea.png";
import ratIcon from "./assets/rat.png";
import crossIcon from "./assets/cross.png";
import lightIcon from "./assets/light.png";
import boostIcon from "./assets/boost.png";
import closeIcon from "./assets/closeicon.png";
import launchIcon from "./assets/launch.png";
import arrow from "./assets/arrow.png";
import energyIcon from "./assets/energy.png";
import rocketIcon from "./assets/rocketicon.png";
import tap from "./assets/tap1.png";
import cellIcon from "./assets/cell1.png";
import standHummanIcon from "./assets/standhumanicon.png";
import {
  BOTTOM_TAB,
  CARD_IMAGES,
  COIN_TABS,
  TASKS_DATA,
  WEEKLY_REWARD,
  INVITE_FRIENDS_DATA,
} from "./constant";
import { motion } from "framer-motion";
import useUserInfo from "./hooks/userInfo";
import { toast, Toaster } from "react-hot-toast";
import FreeBoosters from "./Components/FreeBoosters/FreeBoosters";
import PaidBoosters from "./Components/PaidBoosters/PaidBoosters";
import TaskCard from "./Components/TaskCard/TaskCard";
import {
  getLevelFromCoinBalance,
  getNextLevelFromCurrent,
  findMultiTapLevel,
  getClaimedStatus,
  getTaskIcon,
  formatNumber
} from "./constant/helper";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomCopiedIcon from "./Components/CustomCopiedIcon/CustomCopiedIcon";
import CustomCopyIcon from "./Components/CustomCopyIcon/CustomCopyIcon";
import { BOT_URL } from "./api/url";
import AnimatedNumber from "./Components/AnimatedNumber/AnimatedNumber";
import "./App.css";
import MineModal from "./Components/MineModal";
import MineClaimModal from "./Components/MineClaimModal";
import DailyComboCard from "./Components/DailyComboCard";

const App = () => {
  const [isShowRatIcon, setIsShowRatIcon] = useState(true);
  const [selectedCoinTab, setSelectedCoinTab] = useState(COIN_TABS[0]);
  const [selectedTaskTab, setSelectedTaskTab] = useState(null);
  const [animationClass, setAnimationClass] = useState("");
  const [animatedNumbers, setAnimatedNumbers] = useState([]);

  // const [showMineModal, setShowMineModal] = useState(false);
  // const [categoryData, setCategoryData] = useState({});
  const [selectShow, setselectShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    userData,
    settingData,
    handleTap,
    handleFullEnergy,
    isBottomSheetOpen,
    setIsBottomSheetOpen,
    handleInfoCTA,
    selectedTab,
    setSelectedTab,
    handleTurbo,
    handelMultiTap,
    handleEnergyLimit,
    isWelcomeRewardClaimed,
    prices,
    handleWelcomePoints,
    handleTaskSocial,
    handleClaimBonus,
    selectedDailyReward,
    setSelectedDailyReward,
    isCopied,
    copyReferralLink,
    tg_id,
    turboStatus,
    showMineModal,
    setShowMineModal,
    categoryData,
    setCategoryData,
    handleMineClaim,
    ponchBotPopup,
    handlePonchBotPopup,
    dailyComboArr
  } = useUserInfo();

  const [ready, setReady] = useState(null);

  const handleUser = () => {
    console.log("userClicked!");
  };
  const handleNetwork = () => {
    console.log("networkClicked!");
  };

  const handlestartMining = () => {
    console.log("startMiningClicked!¯");
  };

  const handleSelectedTab = (idx) => {
    setSelectedTab(BOTTOM_TAB[idx]);
  };

  const handleSelectedCoinTab = (tabItem) => {
    setSelectedCoinTab(tabItem);
  };

  const handleEarnCoinTasks = (item) => {
    setSelectedTaskTab(item);
    handleInfoCTA();
  };

  const tapCoin = () => {
    if (userData?.energy >= userData?.multitap || turboStatus) {
      handleTap();
      // Add animated number
      const id = Date.now();
      setAnimatedNumbers((prevNumbers) => [
        ...prevNumbers,
        { id, number: userData?.multitap * (turboStatus ? 5 : 1) },
      ]);

      // Remove animated number after animation duration
      setTimeout(() => {
        setAnimatedNumbers((prevNumbers) =>
          prevNumbers.filter((item) => item?.id !== id)
        );
      }, 1000);
    }

    // Apply the blink and vibration animation
    setAnimationClass("blink vibrate pink-shadow ");
    setTimeout(() => {
      setAnimationClass("");
    }, 200); // Duration of the animation in milliseconds
  };

  const handleMineModal = (
    name,
    iconName,
    perHourAddOn,
    price,
    categoryId,
    level
  ) => {
    setShowMineModal(true);
    setCategoryData({ name, iconName, perHourAddOn, price, categoryId, level });
  };

  useEffect(() => {
    if (!window.Telegram) {
      setReady(false);
    } else if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      if (
        window.Telegram.WebApp.platform === "android" ||
        window.Telegram.WebApp.platform === "ios"
        // || window.Telegram.WebApp.platform === "unknown"
      ) {
        setReady(true);
      } else {
        setReady(false);
      }
    }
  }, [window.Telegram]);

  if (ready !== null && !ready) {
    return (
      <div
        id="qrContain"
        className="w-full mx-auto flex justify-center items-center min-h-[100vh]"
      >
        <div id="qrcode">
          <h2 className="text-center">
            Leave the desktop.
            <br />
            Mobile gaming rocks!
          </h2>
          <img
            src="/twelve_bot_qr.png"
            alt=""
            style={{ marginBottom: "20dvh" }}
          />
        </div>
      </div>
    );
  }

  if (ready !== null) {
    return (
      <Layout
        selectedTab={selectedTab}
        handleSelectedTab={handleSelectedTab}
        isBottomSheetOpen={isBottomSheetOpen}
      >
        <Toaster position="top-right" />
        <Sheet
          isOpen={isBottomSheetOpen}
          onClose={() => setIsBottomSheetOpen(false)}
          detent="content-height"
        >
          <Sheet.Container
            style={{ backgroundColor: "#011662" }}
            className="border-t-2 border-t-[#BCE7FF] rounded-t-3xl max-h-[75%] pb-10"
          >
            <Sheet.Header className="relative mt-4">
              <div
                className="absolute right-3 cursor-pointer z-10"
                onClick={() => setIsBottomSheetOpen(false)}
              >
                <img
                  src={closeIcon}
                  alt="close"
                  width={32}
                  height={32}
                  className="aspect-square"
                />
              </div>
            </Sheet.Header>
            <Sheet.Content>
              {selectedTab.id === 1 || selectedTab.id === 2 ? (
                <>
                  <div className="aspect-square mx-auto mt-10">
                    <img
                      src={launchIcon}
                      alt="rocket-launch-icon"
                      height={150}
                      width={150}
                      className="aspect-square"
                    />
                  </div>

                  <div className="mx-auto text-[40px] text-white text-center max-w-[295px]">
                    Boost your profit
                  </div>
                  <div className="mx-auto text-white text-center text-base max-w-[295px]">
                    Tap the Mining menu to buy upgrades for your exchange{" "}
                  </div>
                  <div className="mx-auto text-center text-base text-white max-w-[257px] mt-5">
                    Earn even when offline up to 3 hours
                  </div>

                  <Button
                    title="Start mining"
                    onClick={handlestartMining}
                    className="mx-auto min-w-[249px] text-[#BCE7FF] font-medium text-xl h-[74px] rounded-md mt-10"
                    isShowIcon={true}
                    icon={icon12}
                  />
                </>
              ) : null}
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
        {ponchBotPopup.show && <MineClaimModal ponchBotPopup={ponchBotPopup} handlePonchBotPopup={handlePonchBotPopup} />}
        {selectedTab.id === 1 || selectedTab.id === 2 ? (
          <div className="flex p-4 justify-between">
            <Button
              title={userData.username}
              onClick={handleUser}
              className="min-w-[105px] text-[#BCE7FF] font-medium text-xs h-[33px] rounded-md"
            />
            <Button
              title="Network"
              onClick={handleNetwork}
              className="min-w-[105px] text-[#BCE7FF] font-medium text-xs h-[33px] rounded-md"
              overlay={true}
            />
          </div>
        ) : null}

        {selectedTab.id === 1 || selectedTab.id === 2 ? (
          <div className="border-t-2 border-[#BCE7FF] rounded-t-[32px] shadow-custom pb-5">
            <div className="flex p-4 justify-between">
              <Stats
                title="Profit per Tap"
                titleStyle="text-[#0AD774]"
                isShowIcon={true}
                statsText={`+${userData?.multitap}`}
              />
              <Stats
                title="Next League"
                titleStyle="text-[#FFBA07]"
                statsText={formatNumber(getNextLevelFromCurrent(
                  getLevelFromCoinBalance(userData?.coinBalance)
                ))}
              />
              <Stats
                title="Profit per Hour"
                titleStyle="text-[#0AD774]"
                isShowIcon={true}
                statsText={`+${userData?.profitPerHour?.toFixed(2)}`}
                isShowCTA={true}
                handleCTA={handleInfoCTA}
              />
            </div>
            <div className="flex justify-center items-center gap-3 -mt-3">
              <div className="">
                <img src={icon12} alt="tweleve-icon" height={43} width={43} />
              </div>
              <div className="text-white  text-[40px]">
                {formatNumber(userData?.coinBalance)}
              </div>
            </div>
            {selectedTab.id === 1 ? (
              <>
                <div className="w-full -mt-5 animate-custom">
                  <div style={{ position: "relative", top: "150px" }}>
                    {animatedNumbers.map((item) => (
                      <AnimatedNumber
                        key={item.id}
                        number={item.number}
                        animationClass="ove-up"
                      />
                    ))}
                  </div>
                  <motion.div
                    whileTap={{ scale: 0.85 }}
                    className={`aspect-square flex justify-center cursor-pointer relative`}
                    onClick={tapCoin}
                  >
                    <img src={tapIcon} alt="tap-area" className="" />
                    {isShowRatIcon ? (
                      <img
                        src={ratIcon}
                        alt="tap-area"
                        height={260}
                        width={260}
                        className="absolute top-10"
                      />
                    ) : null}
                  </motion.div>
                </div>
                <div className="px-4 -mt-5">
                  <div className="flex justify-between ">
                    <div className="flex items-center gap-2">
                      <div>
                        <img
                          src={crossIcon}
                          alt="cross-icon"
                          width={20}
                          height={20}
                        />
                      </div>
                      <div className="text-white tet-xs font-medium">
                        {userData?.league}
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="text-[#B4B0B0] tet-xs font-medium">
                        Level
                      </div>
                      <div className="text-white tet-xs font-medium">{`${getLevelFromCoinBalance(
                        userData?.coinBalance
                      )}/${settingData?.leaguesName?.length}`}</div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Line
                      percent={(userData?.energy / userData?.maxEnergy) * 100}
                      strokeWidth={4}
                      trailWidth={4}
                      strokeColor="#D9D9D9"
                      trailColor="#ffffff"
                    />
                  </div>
                  <div className="flex justify-between mt-3">
                    <div className="flex items-center gap-2">
                      <div>
                        <img
                          src={lightIcon}
                          alt="light-icon"
                          width={20}
                          height={20}
                        />
                      </div>
                      <div className="text-white tet-xs font-medium">
                        {`${userData?.energy} / ${userData?.maxEnergy}`}
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>
                        <img
                          src={boostIcon}
                          alt="boost-icon"
                          width={20}
                          height={20}
                        />
                      </div>
                      <div
                        className="text-white tet-xs font-medium cursor-pointer"
                        onClick={() => handleSelectedTab(4)}
                      >
                        Boost
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-between mx-[10px]">
                  <DailyComboCard dailyComboArr={dailyComboArr} cardImages={CARD_IMAGES} />
                </div>
                <div className="bg-[#012584] text-white font-semibold text-xs p-2 mt-1">
                  Boost your Profit by Pairing The Shio Together
                </div>
                <div className="grid grid-cols-2 px-2 gap-y-5 gap-x-2 mt-5 relative">
                  {/* <div className="bg-overlay text-white">Locked</div> */}
                  {settingData && userData?.toyFactory?.length && settingData?.toyFactory?.map((category) => {

                    const userLevel = userData?.toyFactory?.find(
                      (market) => market.categoryId == category._id
                    );
                    return category?.levels?.map((level, index) => {
                      const maxLevelReached =
                        index === userLevel?.level - 1 &&
                        userLevel?.level === category?.levels.length;
                      if (index === userLevel?.level || maxLevelReached) {
                        return (
                          <div
                            key={category._id}
                            className="mine-card flex justify-between"
                            onClick={() =>
                              userLevel.level === category.levels.length
                                ? undefined
                                : handleMineModal(
                                  category.name,
                                  category.iconName,
                                  level.perHourAddOn,
                                  level.price,
                                  category._id,
                                  index
                                )
                            }
                          >
                            <div className="flex text-white items-center gap-1 justify-around w-full">
                              <div className="w-[60px]">
                                <img
                                  src={
                                    CARD_IMAGES.find(
                                      (image) => image.alt === category.iconName
                                    )?.icon
                                  }
                                  alt={category.name}
                                  className="w-full"
                                />
                              </div>
                              <div className="text-[10px]">{category.name}</div>
                              <div className="flex flex-col items-center">
                                <div className="text-[8px]">
                                  Profit per hour
                                </div>
                                <div className="text-[10px]">
                                  {level.perHourAddOn}
                                </div>
                                <div className="text-[8px]">
                                  Level {level._id} : {level.price} poin
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    });
                  })}
                  {showMineModal && (
                    <MineModal
                      categoryData={categoryData}
                      setShowMineModal={setShowMineModal}
                      handleMineClaim={handleMineClaim}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        ) : null}

        {selectedTab.id === 3 ? (
          <>
            <div className="mx-auto pt-4">
              <div className="mx-auto flex justify-center">
                <img
                  src={tapIcon}
                  alt="tweleve-icon"
                  height={136}
                  width={136}
                />
              </div>
              <div className="text-white  text-[40px] text-center mt-3">
                Earn more coin
              </div>
            </div>

            <div className="w-full flex  border-b border-[#0053D0] mt-5  gap-5">
              {COIN_TABS.map((tab) => (
                <div
                  className={`text-xs flex items-center justify-center cursor-pointer min-w-[70px] min-h-[30px] text-center ${selectedCoinTab.id === tab.id
                    ? "text-[#FFBA07] border-b -pb-1 border-b-[#FFBA07]"
                    : "text-white"
                    }`}
                  key={tab.id}
                  onClick={() => handleSelectedCoinTab(tab)}
                >
                  {tab.name}
                </div>
              ))}
            </div>

            {selectedCoinTab.id === 1 ? (
              <div className="mt-10 px-5">
                <TaskCard
                  title="Welcome Point"
                  icon={standHummanIcon}
                  coins={settingData?.welcomeReward}
                  handleClaimBonus={handleWelcomePoints}
                  buttonTitle={`${isWelcomeRewardClaimed === -1 ||
                    isWelcomeRewardClaimed === undefined
                    ? "Claim"
                    : "Claimed"
                    }`}
                />

                {settingData?.socials[0].tasks?.map((item) => (
                  <TaskCard
                    key={item?._id}
                    title={item.name}
                    icon={getTaskIcon(item)}
                    coins={item?.reward}
                    handleClaimBonus={() => handleTaskSocial(item)}
                    buttonTitle={getClaimedStatus(
                      item,
                      userData?.socialsJoined
                    )}
                  />
                ))}
              </div>
            ) : (
              <div className="mt-10 px-2">
                <div className="border border-[#BCE7FF] bg-gradient-to-tr from-[#001F2F] to-[#2AABEE] min-h-[207px] rounded-lg">
                  <div className="text-white font-semibold p-5">
                    DAILY REWARD
                  </div>
                  <div className="flex flex-wrap px-7 gap-x-1 gap-y-2 pb-5">
                    {settingData?.dailyReward?.map((item, index) => (
                      <div
                        key={index}
                        className={`w-[40px] h-[70px] border rounded-lg flex items-center justify-center text-[10px] font-semibold cursor-pointer ${(selectedDailyReward !== null &&
                          index === selectedDailyReward) ||
                          (index < userData?.dailyReward?.length &&
                            userData?.dailyReward[index] === 0)
                          ? "bg-[#FFBA07] text-black border-[#FFBA07]"
                          : index < userData?.dailyReward?.length &&
                            userData?.dailyReward[index] > 0
                            ? "border-[#FFBA07] text-[#FFBA07]"
                            : "text-white"
                          }`}
                        onClick={() => {
                          if (
                            index < userData?.dailyReward?.length &&
                            userData?.dailyReward[index] > 0
                          ) {
                            setSelectedDailyReward(index);
                          }
                        }}
                      >
                        {`Day ${index + 1}`}
                      </div>
                    ))}
                  </div>
                  <Button
                    title={`${!selectedDailyReward ||
                      userData?.dailyReward[selectedDailyReward] === 0
                      ? "Come Back Tomorrow"
                      : "Claim"
                      }`}
                    onClick={handleClaimBonus}
                    className="max-w-fit  text-black  font-semibold h-[40px] min-w-[100px] rounded-md mt-4 float-right mx-7 px-2 py-2"
                    bgColor="bg-[#FFBA07]"
                  />
                </div>
              </div>
            )}
          </>
        ) : null}
        {selectedTab.id === 4 ? (
          <>
            <div className="mx-auto pt-4">
              <div className="mx-auto flex justify-center">
                <img
                  src={tapIcon}
                  alt="tweleve-icon"
                  height={136}
                  width={136}
                />
              </div>
              <div className="text-white  text-[40px] text-center mt-3">
                Invite More Friends
              </div>

              <div>
                {INVITE_FRIENDS_DATA.map((item) => (
                  <div
                    className="bg-[#002B6A] rounded-md w-[90%] mx-auto my-5 p-4"
                    key={item.id}
                  >
                    <div>
                      <img
                        src={item.icon}
                        alt={item.alt}
                        width={56}
                        height={28}
                      />
                    </div>
                    <div className="flex justify-between mt-2">
                      <div className="text-white text-xs">{item.title}</div>
                      <div className="flex gap-1">
                        <div>
                          <img
                            src={icon12}
                            alt="icon-12"
                            height={16}
                            width={16}
                          />
                        </div>
                        <div className="text-xs text-white">{item.coins}</div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="flex flex-row gap-2 px-4">
                  <div className="bg-[#002B6A] text-white h-[50px] rounded-lg w-full items-center justify-center flex text-[14px]">
                    Invite a Friend
                  </div>
                  <CopyToClipboard
                    text={`${BOT_URL}?start=${tg_id}`}
                    onCopy={copyReferralLink}
                  >
                    {isCopied ? (
                      <div className="bg-[#002B6A] h-[50px] w-[50px] rounded-lg shrink-0 flex items-center justify-center">
                        <CustomCopiedIcon className={`text-white`} />
                      </div>
                    ) : (
                      <div className="bg-[#002B6A] h-[50px] w-[50px] rounded-lg shrink-0 flex items-center justify-center">
                        <CustomCopyIcon className={`text-white`} />
                      </div>
                    )}
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {selectedTab.id === 5 ? (
          <div className="pt-4 px-5">
            <div className="mx-auto mt-10 flex justify-center">
              <img
                src={launchIcon}
                alt="rocket-launch-icon"
                height={150}
                width={150}
                className=""
              />
            </div>
            <div className="text-white text-[40px] text-center mt-3">
              Booster
            </div>

            <div className="text-xs font-bold text-white mt-5">
              Free Daily Boosters
            </div>

            <FreeBoosters
              title="Full Energy"
              subtitle="Fill Your energy to the maximum"
              icon={energyIcon}
              coins="Free"
              buttonTitle="Get It"
              dailyUses={`${userData.fullTankUses}/3`}
              handleFreeBoosters={handleFullEnergy}
            />

            <FreeBoosters
              title="Turbo"
              subtitle="Multiply tap income by 5 for 20 seconds without using battery"
              icon={rocketIcon}
              coins="Free"
              buttonTitle="Get It"
              dailyUses="3/3"
              handleFreeBoosters={handleTurbo}
            />

            <div className="text-xs font-bold text-white mt-5">Boosters</div>

            <PaidBoosters
              title="Multitap"
              subtitle="Increase the amount of coin you earn per tap"
              coins={prices.multiTapPrice}
              icon={tap}
              level={findMultiTapLevel(
                prices.multiTapPrice,
                settingData.multiTapPrice
              )}
              buttonTitle="Upgrade Taps"
              des={`+1 coin for tap for level`}
              handlePaidBooster={() => handelMultiTap(prices.multiTapPrice)}
            />

            <PaidBoosters
              title="Energy Limit"
              subtitle="Increase the amount of energy"
              coins={prices.energyLimitPrice}
              icon={cellIcon}
              level={findMultiTapLevel(
                prices.energyLimitPrice,
                settingData.energyLimitPrice
              )}
              buttonTitle="Upgrade Limit"
              des={`+500 energy for level`}
              handlePaidBooster={() =>
                handleEnergyLimit(prices.energyLimitPrice)
              }
            />
          </div>
        ) : null}
      </Layout>
    );
  }
};

export default App;
