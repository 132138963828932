import { LEAGUE_DATA } from ".";

import teleGramIcon from "../assets/telegramicon.png";
import userIcon from "../assets/usersicon.png";
import youtubeIcon from "../assets/youtubeicon.png";
import standHumanIcon from "../assets/standhumanicon.png";

export const getLevelFromCoinBalance = (balance) => {
  if (balance >= 5000000) {
    return 11;
  } else if (balance >= 1000000) {
    return 10;
  } else if (balance >= 500000) {
    return 9;
  } else if (balance >= 250000) {
    return 8;
  } else if (balance >= 100000) {
    return 7;
  } else if (balance >= 50000) {
    return 6;
  } else if (balance >= 30000) {
    return 5;
  } else if (balance >= 15000) {
    return 4;
  } else if (balance >= 5000) {
    return 3;
  } else if (balance >= 1000) {
    return 2;
  } else {
    return 1;
  }
};

export const getNextLevelFromCurrent = (currentLevel) => {
  const nextLevel = currentLevel + 1;
  return LEAGUE_DATA?.find((item) => item?.level === nextLevel).minRange;
};

export const findMultiTapLevel = (value, arr) => {
  return arr?.findIndex((item) => item === value) + 1;
};

export const getClaimedStatus = (currentSocialObj, joinedSocial) => {
  if (joinedSocial) {
    const isExistInJoined = joinedSocial[0]?.tasks?.find(
      (item) => item?.taskId === currentSocialObj?._id
    );
    if (isExistInJoined) {
      if (isExistInJoined?.claimed) {
        if (isExistInJoined?.done) {
          return "Claimed";
        }
        return "Check";
      }
    } else {
      return "Redirect";
    }
  }
};

export const getTaskIcon = (currentTask) => {
  if (currentTask?.name === "Subscribe Telegram") {
    return teleGramIcon;
  } else if (currentTask?.name === "Subscribe YouTube") {
    return youtubeIcon;
  } else if (currentTask?.name === "Invite Friends") {
    return userIcon;
  } else {
    return standHumanIcon;
  }
};

export const formatNumber = (value) => {
  if (value === null || value === undefined) return '0';

  const num = Number(value);
  const absNum = Math.abs(num);
  let formattedNumber;

  if (absNum >= 1_000_000_000) {
    formattedNumber = (num / 1_000_000_000).toFixed(absNum >= 10_000_000_000 ? 0 : 1) + 'B';
  } else if (absNum >= 1_000_000) {
    formattedNumber = (num / 1_000_000).toFixed(absNum >= 10_000_000 ? 0 : 1) + 'M';
  } else if (absNum >= 1_000) {
    formattedNumber = (num / 1_000).toFixed(2) + 'K'; // Always show up to 2 decimal places for thousands
  } else if (absNum < 1000) {
    formattedNumber = num.toFixed(absNum < 1 ? 2 : 0); // Fix to 2 decimal places if less than 1, otherwise to 0
  } else {
    formattedNumber = num.toLocaleString("en-US").replaceAll(",", " ");
  }

  // Remove unnecessary decimal part if it's .00
  formattedNumber = formattedNumber.replace(/\.00$/, '');

  return formattedNumber;
}
