import axios from "axios";
import Url from "./url";
import { toast } from "react-hot-toast";
const queryParams = new URLSearchParams(window.location.search);
export const tg_id = queryParams.get("tgid");
// export const tg_id = "123456987";
const username = queryParams.get("username");
const referredBy = queryParams.get("referralid");

export const getUserInfo = async (userId) => {
  try {
    const response = await axios.get(`${Url.GET_USER_INFO}/${userId}`);
    if (response?.data?.success) {
      return response.data.user;
    } else {
      return response.data;
    }
  } catch (error) {
    if (error?.response?.data?.message === "User not found") {
      const response = await axios.post(
        `${Url.GET_USER_INFO}/register`,
        { tg_id, username, referredBy },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response?.data?.success) {
        return response.data.user;
      } else {
        return response.data;
      }
    }
    toast.error(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
  }
};

export const getSettingsDetails = async () => {
  try {
    const response = await axios.get(`${Url.SETTING_INFO}`);
    if (response?.data?.success) {
      return response.data.setting;
    } else {
      return response.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
  }
};

export const updateUserInfo = async (userData, userId) => {
  try {
    const response = await axios.put(
      `${Url.GET_USER_INFO}/${userId}`,
      userData
    );
    if (response?.data?.success) {
      return response.data.user;
    } else {
      return response.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
  }
};
