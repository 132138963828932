import React, { useState } from "react";
import { Sheet } from "react-modal-sheet";
import Button from "../Button/Button";
import icon12 from "../../assets/icon12.png";
import arrow from "../../assets/arrow.png";
import closeIcon from "../../assets/closeicon.png";

const PaidBoosters = ({
  icon,
  title,
  coins,
  handlePaidBooster,
  buttonTitle,
  subtitle,
  level,
  des,
}) => {
  const [isPaidBoosterBottomSheetOpen, setIsPaidBoosterBottomSheetOpen] =
    useState(false);

  return (
    <>
      <Sheet
        isOpen={isPaidBoosterBottomSheetOpen}
        onClose={() => setIsPaidBoosterBottomSheetOpen(false)}
        detent="content-height"
      >
        <Sheet.Container
          style={{ backgroundColor: "#011662" }}
          className="border-t-2 border-t-[#BCE7FF] rounded-t-3xl max-h-[75%] pb-10"
        >
          <Sheet.Header className="relative mt-4">
            <div
              className="absolute right-3 cursor-pointer z-10"
              onClick={() => setIsPaidBoosterBottomSheetOpen(false)}
            >
              <img
                src={closeIcon}
                alt="close"
                width={32}
                height={32}
                className="aspect-square"
              />
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <div className="aspect-square mx-auto mt-10">
              <img
                src={icon}
                alt={title}
                height={100}
                width={100}
                className="aspect-auto"
              />
            </div>
            <div className="text-center text-white text-2xl mt-7">{title}</div>
            <div className="text-center text-white text-xs mt-5">
              {subtitle}
            </div>
            <div className="text-center text-white text-xs mt-5">
              {des}&nbsp;{level}
            </div>

            <div className="flex gap-4 justify-center items-center mt-7">
              <div>
                <img src={icon12} alt="icon-12" height={35} width={35} />
              </div>
              <div className="text-center text-white text-3xl">
                {coins}
                <span className="text-white/80 font-semibold">
                  &nbsp;&nbsp;. Lv {level}
                </span>
              </div>
            </div>

            <Button
              title={buttonTitle}
              onClick={handlePaidBooster}
              className="mx-auto min-w-[249px] text-[#BCE7FF] font-medium text-xl h-[74px] rounded-md mt-10"
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
      <div
        className="bg-[#002B6A] rounded-md min-w-[300px] flex items-center justify-between my-4 px-5 py-2 cursor-pointer"
        onClick={() => setIsPaidBoosterBottomSheetOpen(true)}
      >
        <div className="flex items-center gap-2">
          <div>
            <img src={icon} alt={title} width={28} height={28} />
          </div>
          <div>
            <div className="text-xs text-white">{title}</div>
            <div className="flex gap-1">
              <div>
                <img src={icon12} alt="icon-12" height={16} width={16} />
              </div>
              <div className="text-xs text-white">
                {coins}
                <span className="text-white/80 font-semibold">
                  &nbsp;&nbsp;. Lv {level}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img src={arrow} alt="arrow-icon" width={10} height={10} />
        </div>
      </div>
    </>
  );
};

export default PaidBoosters;
