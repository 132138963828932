import home from "../assets/Home.png";
import selectedHome from "../assets/Home-1.png";
import card from "../assets/Cards.png";
import selectedCard from "../assets/Cards-1.png";
import earn from "../assets/Earn.png";
import selectedEarn from "../assets/Earn-1.png";
import friends from "../assets/Friends.png";
import selectedFriends from "../assets/Friends-1.png";
import rat from "../assets/rat-icon.png";
import horse from "../assets/horse-icon.png";
import ox from "../assets/ox-icon.png";
import goat from "../assets/goat-icon.png";
import tiger from "../assets/tiger-icon.png";
import rooster from "../assets/rooster-icon.png";
import dragon from "../assets/dragon-icon.png";
import monkey from "../assets/monkey-icon.png";
import rabbit from "../assets/rabbit-icon.png";
import dog from "../assets/dog-icon.png";
import snake from "../assets/snake-icon.png";
import pig from "../assets/pig-icon.png";
import telegramIcon from "../assets/telegramicon.png";
import userIcon from "../assets/usersicon.png";
import youtubeIcon from "../assets/youtubeicon.png";
import userGroup from "../assets/userGroup.png";
import shuttle from "../assets/shuttle.png";
import selectedShuttle from "../assets/shuttle(1).png";

export const BOTTOM_TAB = [
  { id: 1, alt: "home-tab", selectedIcon: home, icon: selectedHome },
  { id: 2, alt: "card-tab", selectedIcon: selectedCard, icon: card },
  { id: 3, alt: "earn-tab", selectedIcon: selectedEarn, icon: earn },
  { id: 4, alt: "friends-tab", selectedIcon: selectedFriends, icon: friends },
  { id: 5, alt: "boost-tab", selectedIcon: selectedShuttle, icon: shuttle },
];

export const CARD_IMAGES = [
  { id: 1, alt: "rat", icon: rat },
  { id: 2, alt: "horse", icon: horse },
  { id: 3, alt: "ox", icon: ox },
  { id: 4, alt: "goat", icon: goat },
  { id: 5, alt: "tiger", icon: tiger },
  { id: 6, alt: "rooster", icon: rooster },
  { id: 7, alt: "dragon", icon: dragon },
  { id: 8, alt: "monkey", icon: monkey },
  { id: 9, alt: "rabbit", icon: rabbit },
  { id: 10, alt: "dog", icon: dog },
  { id: 11, alt: "snake", icon: snake },
  { id: 12, alt: "pig", icon: pig },
];

export const COIN_TABS = [
  {
    id: 1,
    name: "Tasks",
  },
  {
    id: 2,
    name: "Rewards",
  },
];

export const TASKS_DATA = [
  {
    id: 1,
    title: "Subscribe Telegram",
    subtitle: "Channel",
    coins: "+5000",
    icon: telegramIcon,
  },
  {
    id: 2,
    title: "Subscribe YouTube",
    subtitle: "Channel",
    coins: "+5000",
    icon: youtubeIcon,
  },
  {
    id: 3,
    title: "Invite Friends",
    subtitle: "",
    coins: "+10000",
    icon: userIcon,
  },
];

export const WEEKLY_REWARD = [
  {
    id: 1,
    name: "Day 1",
    isDayActive: true,
  },
  {
    id: 2,
    name: "Day 2",
    isDayActive: false,
  },
  {
    id: 3,
    name: "Day 3",
    isDayActive: false,
  },

  {
    id: 4,
    name: "Day 4",
    isDayActive: false,
  },
  {
    id: 5,
    name: "Day 5",
    isDayActive: false,
  },
  {
    id: 6,
    name: "Day 6",
    isDayActive: false,
  },
  {
    id: 7,
    name: "Day 7",
    isDayActive: false,
  },
];

export const INVITE_FRIENDS_DATA = [
  {
    id: 1,
    icon: userGroup,
    alt: "invite-friends",
    coins: "+500",
    title: "Invite Friends",
  },
  {
    id: 2,
    icon: userGroup,
    alt: "invite-friends",
    coins: "+2500",
    title: "Invite Friends with Telegram Premium",
  },
];

export const LEAGUE_DATA = [
  {
    id: 1,
    level: 1,
    leagueName: "Wooden Unicorn",
    minRange: 0,
  },
  {
    id: 2,
    level: 2,
    leagueName: "Bronze Unicorn",
    minRange: 1000,
  },
  {
    id: 3,
    level: 3,
    leagueName: "Silver Unicorn",
    minRange: 5000,
  },
  {
    id: 4,
    level: 4,
    leagueName: "Gold Unicorn",
    minRange: 15000,
  },
  {
    id: 5,
    level: 5,
    leagueName: "Celestial Unicorn",
    minRange: 30000,
  },
  {
    id: 6,
    level: 6,
    leagueName: "Diamond Unicorn",
    minRange: 50000,
  },
  {
    id: 7,
    level: 7,
    leagueName: "Mystic Horn",
    minRange: 100000,
  },
  {
    id: 8,
    level: 8,
    leagueName: "Ethereal Gallop",
    minRange: 250000,
  },
  {
    id: 9,
    level: 9,
    leagueName: "Luminous Alicorn",
    minRange: 500000,
  },
  {
    id: 10,
    level: 11,
    leagueName: "Galactic Guardian",
    minRange: 1000000,
  },
  {
    id: 11,
    level: 11,
    leagueName: "Mythical Majesty",
    minRange: 5000000,
  },
];
